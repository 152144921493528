// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BannerGoogleMaps from "../../../../../../styleguide/components/BannerGoogleMaps/BannerGoogleMaps.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as AllJson from "./map-data/all.json";

var allLocationsJson = AllJson;

function MapHivelocityBareMetal(props) {
  return JsxRuntime.jsx(BannerGoogleMaps.make, {
              title: "Bare Metal Regions and Availability",
              description: "Hivelocity global coverage: 40+ locations in 26 regions - industry best coverage compared to the competition.",
              allLocationsJson: allLocationsJson,
              color: "Red"
            });
}

var make = MapHivelocityBareMetal;

var $$default = MapHivelocityBareMetal;

export {
  allLocationsJson ,
  make ,
  $$default as default,
}
/* allLocationsJson Not a pure module */
